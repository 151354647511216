import "../../core/src/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWTW/bMAy951cQAwY0wxQ4bpK17goM22W3/YBhB9qiEy2KZEh0Pjb0vw+WHdd2nTY7BaHJx6enR9qzrb5dxVEEfycAByV5k8A8it4/TAB2eBRNaI/uRgjMc2VIkFRsXf1oWie6tTJCU84JYMm2E3RqvelEC5RSmXWTOwbrlSTRpH2EeDHtljVoV9fl1rDw6g8NakI8RU/Th8nTZFaLMA8iSOULjacEck3HCuN36VnlJ5FZw2Q4AV9gRiIlPhCZKgO1WhuhmHY+gYwMk+vAxgE2dDxQzX8ZRQN282URmumK3aZJixd1MLPausEJmI4sCqd26Cpq2rruUW5DzxbnrsZJrZPkhEOpSp9AL5rAvDiCt1rJfqOm6NwCIMVsu3a2NFKMEdvHIi2ZrRGeMmskulMoC4SDUs8a9SWIxyRoSI7Jd7UsAFnpfJVbWDW4nUVQqjFrapntLoH5qura5ixDzsV27Tk7Dc/8b8cOVQdb+NXY8LW5UQ3wwpajpgNYY5FA45vDRjGJYNcEjD04LEZssOiTSTZ2Ty5QevumNZ7IiU5eKP6xJ6fxNO2dcdZUOcrJkclIsGJNdaOa0Vl+Yw31av1+ffEK9rFQ2cBtL05zDUDjmm75p/GNUP0KqRxlrGxlZ6vLnWnVPxu5MRXb5xt53Wd3oV1/SeW4U/o0tr48Gt887nlcUo6l5u5oR7Pl+HBXw4rZViuz9SLVNtt+DSXTy+uiWbHt/wuSdgfx2rUxYNIWfBuut7v/MukA93tV2kODD7U5hgK2Gfevmae/A4avndGtVt/7+E5sm2KP1MvNlYbn7NB4VfsQtYZoFnsg9CSUEbbkV65o4Poe7TjqOTP9KZGxElhj4Uk+vmNX0rtfzxRy63YJOMvIdHMfSVpP32rdn9gvO5IKoXDKcICddT9NOlNYbweAp6qoeimjMuRgr+hQWMdwEzYpfH6E1SIqjtMxsNbEUfuSfZo8/QMp9kp8DQkAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'kl3620b';
export var container = 'kl36200';
export var link = 'kl36206';
export var linkPreview = 'kl36208';
export var linkPreviewContainer = 'kl36207';
export var linkPreviewRenderer = 'kl3620a';
export var linksContainer = 'kl36204';
export var linksTitles = 'kl36205';
export var notFound = 'kl36209';
export var showButton = 'kl36203';
export var title = 'kl36202';
export var titleLine = 'kl36201';