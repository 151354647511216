export const EMBED_IFRAME_DEFAULT_WIDTH_IN_SURFACE = 752;
export const EMBED_IFRAME_DEFAULT_HEIGHT_IN_SURFACE = 116;
export const EMBED_IFRAME_DEFAULT_CONTAINER_BORDER_RADIUS = 8;

export const DEFAULT_IFRAME_HEIGHT = 152;
export const DEFAULT_IFRAME_WIDTH = '100%';

export const LINK_CREATE_POPUP_OFFSET = 4;

export const IDLE_CARD_DEFAULT_HEIGHT = 48;
export const LOADING_CARD_DEFAULT_HEIGHT = 114;
export const ERROR_CARD_DEFAULT_HEIGHT = 114;
