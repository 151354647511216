import "../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4VUy27bMBC85yu2BgrUBwaSH21KI19SBAUtrqRtaZIg15bdIv9ekLJiybHT475mZ4cjPf4sl3uvDrtv8PcBYOuCxiCC0rSPEp78cZOzRxFbpV0n4aDCFyFUXZPFc1KU881ltiPNrYRyGM3JyCeDEqIzpHNaVb+b4PZWi8oZFwbcgU0xRrzZscgdN0uJzuvD4xA+5cu80ppsI6H8mpiN6t9zXVP0Rp0k1AYzc2WosYIYd7FPisgqcCr92kem+iQqZxktS4heVSi2yB2inYCrDN4iNS1LWKx6VT5eVqFlDBOYbYY5S3sXxZtE4woFYKdCQ1aEnkNZXN2/hXhoxvhlUXzejFgP8U2xV1OxqzFQkaaydE1I3ilT7A4YapPClrROcgEwHllcCmgM+UgxlbqWGEUWWIJ1XVA+ozrLojsTXBV5k0mWvJY6N0b6gxLKdZ/6jx46n3DVdG0azE03FVlmZ3oXiclZCQGNYjrg1PcSOCgbvQpo+WL2dKPFyar6h1asRG2c4ucZhz3OXnpLv61Q2+jMnvMKdn7gC3CPfnOf/noOn2jnXWCViI2G2rEwec36ncaT30Of383fP86i59Nf1hG3gipnX+Cyqy/l7+J5xsTm7er+QxYGa5awzM/8+g9/1wrzxwQAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = '_13upavmd';
export var actionButton = '_13upavme';
export var actionTextColor = 'var(--_13upavm3)';
export var card = '_13upavm7';
export var cardBorderColor = 'var(--_13upavm2)';
export var cardColor = 'var(--_13upavm0)';
export var cardForeground = 'var(--_13upavm1)';
export var cardInner = '_13upavm8';
export var cardWrapper = '_13upavm6';
export var closeButton = '_13upavmf';
export var closeIcon = '_13upavmg';
export var closeIconColor = 'var(--_13upavm5)';
export var headAlignWrapper = '_13upavma';
export var header = '_13upavm9';
export var icon = '_13upavmb';
export var iconColor = 'var(--_13upavm4)';
export var main = '_13upavmh';
export var title = '_13upavmc';