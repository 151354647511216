import "../../component/src/ui/audio-player/audio-player.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/audio-player/audio-player.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WyZLaMBC98xW6pCocRNkMTIjmlpzyFSlhte0ehOSSxJZU/j1leUHyeCbAkDlRyFL369evl9nPtNwkq6RIyO8JIQJtJfmJkVzC8WlC/C8VaCBzqBUjmZa7raq/rLURYBhJqyOxWqIge24+U8rzHBXQ/ZxKfgJDUVkU8M3fps2j6fk9NVzgzjLyWHl/FRcCVcFIOm8Osp2x2jAiIOc76eqjAwpXMpImySdviGebwuidEjTTsr47DiS4VxnccnPyOApedd7+TGYdHek4HVxioSg62NrmkFrHjUeVa+XoAbAoHSPLJOnPLP4CRtI2wFcgOji6CJbeg8mlPjBSohDgKfeXzh9ASqwsWs9JiQ6orXgGjCh9MLyqj2VtvmxBzRcNhPGI59cKwJtpTda3GEnHgQdOHi6gNQPlwPQeVgOci/ebWHoT92F4y02BipqG4lVLxznvUaKb8+30hV4ea70ECB89wp7V4MuXq8J/E8jRTv+lSAuZVqLRZABi5UF0ulokTdRtXXZ/L8X4vLMO8xPNtHKg3PlT4PDrhzh80ZSWN3eYkLhXKcZMq6Dog3j5jSLv9ej0iO7XV1l9i6YXhDjDla24AeWu6XLdEFFawQj/i8FQaP9vUdE29/M27L7HvazAdBFPkkpjF4dHjU1j41KSZDa3BLiFmDZW1i3Bk/dOKURWBVq+liCablTxDN2JkWS2DNEq7SiXUh9ARM8z/2owCjsWHubXVcRolxRveejG8w18ODAOYwH0CW+tjrXmkfRVukueAckd7uO8QdQ0Ll8XfFnmKOFHptX3+o6lBsR0qBhPDUlmqfVzlsctK29a+B0bcCT8xTBdOZPcOqpz6k5VE7q34FPOiB9Q0YPC3xnj8OY14M474bD8Vx+yEgYUlRfn8H+sa0nU0dfaOb1tSyTAiPdq6j6RzY4YmH++l/l4Cw08bAY65Gur5c55HcZr1XmoBYj60dHn8urx/D7ZhiXVTopBbD3WtdTZ5mny5y9xpL4Cdg0AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var audioIcon = '_1hk080g8';
export var button = '_1hk080gb';
export var controlButton = '_1hk080g9';
export var controls = '_1hk080ga';
export var miniCloseButton = '_1hk080gk';
export var miniNameLabel = '_1hk080gh';
export var miniPlayerContainer = '_1hk080gi';
export var miniProgressContainer = '_1hk080gj';
export var miniRoot = '_1hk080gg';
export var nameLabel = '_1hk080g5';
export var progressBar = '_1hk080gd';
export var progressContainer = '_1hk080gc';
export var progressFill = '_1hk080ge';
export var root = '_1hk080g0';
export var sizeInfo = '_1hk080g7';
export var spacer = '_1hk080g6';
export var timeDisplay = '_1hk080gf';
export var upper = '_1hk080g1';
export var upperLeft = '_1hk080g2';
export var upperRight = '_1hk080g3';
export var upperRow = '_1hk080g4';