import "../../../../blocksuite/affine/fragments/fragment-outline/src/card/outline-card.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/fragments/fragment-outline/src/card/outline-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwY7aMBC98xUW0krLwSiksGW92m9or1W1qiZ4Erw1dmQ7gbTqv1dxEtYJXrRI7QkYmzdv5r0ZL3+smtNr0dQJ+T0jpNRWOKEVIwYlOFHj04wQLmwpoWEkl3hqAyBFoahweLCM7FA5NG34tbJO5A3daeVQufAo0ydqxS+hCkYybTgamunT0+zPbHlm8J2DA2oduMo+z7mBohCqmL/0zESLRbFG5SwjSivPTZewE65hJFluonDaOMgkPs+dqXAAA849lbQ8kaQc81hd6cRRcLdnZJUkd11VvhIDXFSWkXXpu7OrjNWGEY45VNK1ocqioRYl7txAPczI9rpG4/NmsPtZGF0pzkgN5p5SyHOhkNYpldC0fTvfoP5vX2o0EprFtWZ2qZHPX8ikzv+TLxDvH+Ybqf04yp+OdU3IttNiIthBKLpHUewdI2l/5ezuwVLvuLuAkg2wH7BzGog6OKIwkH3IpJO6JgN4CVALKzIhhWue58gLlGjtCCZC5mzP2+E+dUBatjhTFR2eHOXCtgV50eKjG2Rdx4q8fcsEiBuP6Jl4lPD/uVauFQ8n1Lv4wVOOlZYZUJz6k8UZ59ibaZMkbUy2FweDrdaTzfLgWZ2POyu11TKyCtaJ06U/JRzsHvmERXfnjYYzoGyuzYF1XyU4/Ha/Se7GA/p53OPB629rDjKrZeV81HQMe7N7PvSh+9GPWEdyO45l2jl9OIevzVG/KgMtVmkQutbW9GJh9+Z+r9aLodnG/Rbf5sE6irnCpw7kiBYd5H6M556+SP5zfaWPASR4SN+3HA5CNlFjg7L98eKdF+n6RJdGHMBM1j5E9ko/7qN72eRNDc124cmbngjdvQ5fQaFcRFQMF/YeuD5OMLsgTRfX1nqgz/bCuDctkctuTt0dG4N1NwY3SRx2v39l9vr4Eh2Nv7OFkqYGCgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cardContent = '_1yxjgyva _1yxjgyv3';
export var cardHeader = '_1yxjgyv2';
export var cardPreview = '_1yxjgyv1';
export var currentModeLabel = '_1yxjgyv9';
export var displayModeButton = '_1yxjgyv8';
export var displayModeButtonGroup = '_1yxjgyv7';
export var divider = '_1yxjgyv6';
export var headerIcon = '_1yxjgyv4 _1yxjgyv3';
export var headerNumber = '_1yxjgyv5 _1yxjgyv3';
export var modeChangePanel = '_1yxjgyvb';
export var outlineCard = '_1yxjgyv0';