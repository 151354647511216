import "../../core/src/desktop/dialogs/setting/workspace-setting/integration/readwise/setting-dialog.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/integration/readwise/setting-dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V247bIBR8z1egSJU2bbDAcS5l1Zf+RlVVBI5tKgIISOJs1X+vjJ1dO5fu9vJk5eicmTnMQLJvdKPoWuYE/ZggdFQy1gwVG+KaxwlCO97gvia4Fg+UkMMRYbTIXTN7nPycZOd5mualCk7zE0OlhgTAtaoMVhF2gSEBJoJvyxV3DG06DselVKbCWxuj3TFEV119a70E/1wm2dI1KFitJDpw/4AxL0tlAB9yrPkJPFYmKAmfz2PtZ6wxH+3Yr1iDqur48ru0JuKgnoChBRkp8VyqfWBo2VYHsIsEO5ijy25Ot/LO+HkxwD/2xSUhbU1YbT27WitCE7Hzasf9abxJcUWZ36IkNyiL1ykDCGvkFenytsXtF0vlQURlDWuR9zvTpcdXyuBo3YurQ+dH4QjRQxT1iHGVGM/r9FNvzdj3fYiqPGFhTQQT7zAwtoXSekhMz63T6eNLUigh74ZJIf8xnf3xVd4eGaIjaet7Ht9K0E3f3xarZ5/Ou2wu8r1JQuwBfKlbmbWSEszIisormdz1SuIIO6d5BOztMTBEy2RH9NwE1UXkug2RrAhI7LdK4C08KfAPGVnPs81inhWrOZ3NkXVcqHjqOoEHGIv8InnkWFituQsgP02j38P0a9J+SxbpZPWoydUB3Mc7Ow9a+JU/v7nhY3/yf/Inv/Bnm4T0zyhDK9ecr8rFw1UMqwzRv03s5coDLeLeofz5AWguoLb66hWXiUJDGRnCtANKT0z6h2or6EMP2S7eYGedA4+5EbX1vYYZeo8wnQ1ueZrOXxlOvbNWzy85YnsXOQcAAA==\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var dialog = '_18i17d20';
export var divider = '_18i17d26';
export var header = '_18i17d21';
export var headerCaption = '_18i17d24';
export var headerIcon = '_18i17d22';
export var headerTitle = '_18i17d23';
export var settings = '_18i17d25';
export var tagsEditor = '_18i17d2b';
export var tagsLabel = '_18i17d2a';
export var tagsMenu = '_18i17d2d';
export var tagsPlaceholder = '_18i17d2c';
export var updateStrategyGroup = '_18i17d28';
export var updateStrategyGroupContent = '_18i17d29';
export var updateStrategyLabel = '_18i17d27';