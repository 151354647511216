import "../../../../blocksuite/affine/fragments/fragment-outline/src/card/outline-preview.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/fragments/fragment-outline/src/card/outline-preview.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62U0a7aMAyG73mK3EyCi6DScdhZkPYKe4QpTdzWO2kSJWmBM/HuU9oCbYGtZ9plbH9O/NvO+sfmJJo6bRLya0FIbnSgOa9QnRhpuFtSyvMcNdCBZ7VfEHJAGUpGNknyKR4zc6Qe31EXjGTGSXA0M8fosVzK1ryzR/JqW9uhxADUWy6AEW0Ojttoluit4idGcgVt3M/aB8xPVBgdQAdGfOAuRA9XWGiKASrPiAAdwEVzwS3rLjkv1tfSWGkacG2BonbeOEaswQuTcfFWOFNrOSm5Sanip1jJNYK2mb434BSPOpwXay4CNkC+kfVYSmFUvGiaMcAxUKhsyT361eSd2oQlK7lfesv1atXmuYqijYZR/Gbsv4j2RJo7LW+uvpdp2nWnBCzKcDvP6O22j/yIlB6E0ZK7bp76xI5LrP014RMRURhNrcOqoweapH/SvsUkep4pGGOfW+zJWMaW58ocGClRStDR1rbx5gCl0Hr00RX7wMhmf1knj+/waJl81RauomEq+fg8eOj2tqaHPmabJINeUAV5BKdL8HIP7h6ByYja/RP1ZSa1nbzxdSa3marydS64m4B8JpgmEzCbC05rFP9t0gZJ5e013bShLsFhGCxWZkIwFSPJ+sUeiTcK5XgmJTYYA9vdWd390pkD/tYd2imvuCtQU9fP6aRK+OsX+HAN8w9gvTIShHE8oNGsW6VQOlMX5X5x/g15mWu63AYAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var icon = '_1ycvu2v1';
export var iconDisabled = '_1ycvu2v2';
export var linkedDocPreviewUnavailable = '_1ycvu2ve';
export var linkedDocText = '_1ycvu2vd';
export var linkedDocTextUnavailable = '_1ycvu2vf';
export var outlineBlockPreview = '_1ycvu2v0';
export var subtypeStyles = {title:'_1ycvu2v5',h1:'_1ycvu2v6',h2:'_1ycvu2v7',h3:'_1ycvu2v8',h4:'_1ycvu2v9',h5:'_1ycvu2va',h6:'_1ycvu2vb'};
export var text = '_1ycvu2v3';
export var textGeneral = '_1ycvu2v4';
export var textSpan = '_1ycvu2vc';