import "../../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB936/wSyWQMM2NXLwvVb+gv2ASB2bX2JHtwNJq/72yE8AJNLtFywthYvucOWcmk/x4Zcda0R3T6EWnGsoI/XlCKPjmLggZ2RCEk+Tbsws5qw1BOAz72CgqdC3VjqAdNQreZjhYRsUC4WBZpAsULIvEBfFqgYIFCub23PsTQmF6ojhh5idMR7mapMhih5oWliLtKLJsRBFHI4pswJAEUwxF5kCj2DKEaaeoGDEk+VjEakARF1MUeW6xk7yT4n7zEUGaPFpCHjy8DmHw6HZ6f/ox7uN42Mc9Uxw0b4MCXeJb1H1tukKd6zTdx3E2osgmKfKuLqvIUsS91OijTo5GOsIpHaEDDSJ3CaNPtnI00hFNy7ijl79exFUzP6IYXjs/qKven5ZdEyeO5wCV2RLHa9e3DDZbc4kbqcGAFAQpxqmBPbN35Z6pmssDQVuoKiaeL6grHzWPB6B5OMKkay15axzmWa7bc9LaBXcIlQ0twRyJXbRxDdwwRdCat2q2p2qGcZdwOJ8POLBUsAFBUMmEYaq/2C1UwI52iZ/GQKwRo5phEFi2BtVSHaiqNAJRg4BO2PkYbjg9Ym2oYQQ1tNWs8oxLnXG3N6tWCBAbb3fm21zkA5uLbMLmwZAcjsj7BuQDjD4A57jcUrFhxOW4sGkvLkdvViP6ymrk/1WNYvgoJcNyJBPlUN2em21/t7OD+uV+/bo3auy/RD+sx1kldSpvT4QKtLWIIBAcBMM1Z25K2SvWWwXilaDAA1s7sJOCgKBWaGaeLzb6ArtMT4Zer9RSGKzhNyOopLycDXeg7yiaD5I8ZUc5bAQGw3aaeP330moD9RGXUhgmjL+0Z8pASTl2RwnaQVVx50CrmcKacVYagoQU7K7xWX5mKMv1CysNrsGmZsE8gOpTAFcJfIk5a1q+bpRsRYVLyaU6lYrWte2JRsGOqmO35gpya9dhC4a5VddI59TPdT70d1aB30/M6f4gg32EG6mMomAwlyXlv/ro5/nc3MOs/+8N6T/XV25O2PYPo6+1qM2aztJggdJwgVL35b1yTv3GICr21rt00/tW+dZeRst5BPQi+hW0DFfagbVKW7RGwmgc1GRr+8h5dMYLvQ2b0by4Nffy7mNm8BW4Bw1r4A7v0p++xstI6nMYMPrn3X/7gL7/BX1M69mNDgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = 'js6sic7';
export var DefaultAvatarBottomItemWithAnimationStyle = 'js6sic8';
export var DefaultAvatarContainerStyle = 'js6sic4';
export var DefaultAvatarMiddleItemStyle = 'js6sic5';
export var DefaultAvatarMiddleItemWithAnimationStyle = 'js6sic6';
export var DefaultAvatarTopItemStyle = 'js6sic9';
export var avatarDefaultFallback = 'js6sice js6sicd';
export var avatarFallback = 'js6sicd';
export var avatarImage = 'js6sicc';
export var avatarRoot = 'js6sica';
export var avatarWrapper = 'js6sicb';
export var blurVar = 'var(--js6sic1)';
export var hoverWrapper = 'js6sicf';
export var removeButton = 'js6sicg';
export var sizeVar = 'var(--js6sic0)';