import "../../core/src/blocksuite/database-block/properties/member/style.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/database-block/properties/member/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VT246bMBB9z1fMS6X2wRGwbJp6P6LfMLGHMFnHtmxDSKv+e4UJS9JNqrZShQRoLmd8zhyvDzUW8H0F4FFrtnsJWz9AMT4vK4AT69RKqMtnP7ysfqzWhxrL2/pijle38bLyw/TaXn7mwqdceIEui+LDnKhzQnP0Bs8SGkPDeIjxKzQHUomdlaCc6Y52zBxxEC3xvk0SnorC53LXU2iMO0nALrkZ+/k+9qGLiZuzUM4msklC9KhI7CidiPKMG2EmGgB79DlwO69lracm1YXoggTv2CYKY2jngqYgAmruooR6ak4BbeSJ1w7V6z64zmqhnHEBinUVgTDSGwnZjtMylV+rJfQYPgqBTcOWRF8Jg2cK4qouN3/tKRg8f1owUSXu6V9BIylnNYYFcXNfajS8t4ITHaMERbMuv5dyQvycEe8JnWhIYkmQMewjx+zdlhOJvE8J1p0C+uwmZ5OI/I0klJclmJHb7KPqyqnb904F8G5eWCCDo3RvNDYT3n0HjweQMB/jEdUveWRuiG1g+3p1w/APPbyI+0Dz5ZJuFrK7/4QO8MBKeXfv7aP+3j71Q/v8BPz8HwfhBAAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatar = 'j4a9';
export var cellContainer = 'j4a8';
export var loadingContainer = 'j4aa';
export var memberItem = 'j4a5';
export var memberItemContent = 'j4a6';
export var memberListContainer = 'j4a4';
export var memberName = 'j4a7';
export var memberPopoverContainer = 'j4a0';
export var memberPopoverContent = 'j4a1';
export var memberPreviewContainer = 'j4ac';
export var noResultContainer = 'j4ab';
export var searchContainer = 'j4a2';
export var searchInput = 'j4a3';