import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Xy27jOBC8+ysIAwMkQBjInsTjUTDA/sF+QJADJbak3tCkQFJyvIv8+4KknrakOLM57MWQaHaxWd1VpO5FvYkSFZF/VoRwNKVgp5hkAt6eVoTkrIzJQ+mfDyhpAZgXNibfozBWKoMWlYyJBsEs1uBGXTQ9ahfrfp9W76v7Zplnrlmes0TAr7XVFaxf/MJppY3SMck1S5anP3NmGfXDKPMRSI0GExRoTzEpkHOQy1BxApnSEBJQ0oK0MVmvn4ZEJEKlr+OdssQoUVm/03HehFhVxmTzGMgRkNmYRO4xUZqDpppxrExMtmFCwtLXXKtKcpoq4XBqpm8oZVmGEmi9pRbeLC0FS6FQgoO+9YtoJk2m9CEOj4JZuKG78u2O0MfoWz+nSTgUjUT3W0Oi+425I6pkKdpTP+RCmsEm47bSTfGPyG0xeBOCpgWTObTwHegHnLPMgv4/Ue6ZKpkGaefJ3Y/IbbnZRNG3ATn7/0xOoWrQw7bsVmrS72q0GSBtprXrVchRQxpITJWoDrJTtc+2w9h6DLd8JtSx10+fQnTpAq0JMM5R5t37lA5dNVFa0BRqkNbERCoJc1Xu0vo+vTUmMJfUgMjCGDWWabu86b8qYzE70a7tuphpEzvrn6bzMyUtNfg3nGk1jB9uR3y0MT7DQqN8bXpTuJCWxW1T28qAdnuC1PbsLPmCBW2R6dPtoAc3u6YIY6fuCQ3+WTBDDyCrSRNuKvVxVOhXH/uxlQl2Ak0H83zwnzVowdwW3le9tdNSQ41wfCFtAotLjSTcJf1wnSy0OvYthRYOJiYpBAIaqexGFD4u9eQFwEXb9X9NO9Sgwza7MDTDKKbKiSBVkocu6FLc+RT9TnPt5Lx5mlG3b6T+DxACS4P+PDgWaIGakqXg+jGc5NcooEvjx+9XYKzqr1bopP6mZDs22h9xptLKUG9wIji0qqxDaxU7mBsPzu3mwLv2jO9RgiY0MK6kOP1aZ0yY7hbkNTF7l7qU8QXa8Eq1DMYhY5Wwl2AXYTGam6DWO9LwdURboLz9MqNoMth7wIWz4IpOuNpf+0POnZzuqGv75PxO7OGP7fUpimZsZE9MnQedfoHi941BtphLAKXGw1n4zy+4QrBP+aIH2J7fIGY/NbpVks+vsjvT85QTdvipx+9O03GJ2/dPVmyCcP6pbVzTyaO2ewxtN2PhC9vnQdWpEoKVBvjYDBbEMtmVwSKH3yJMiPDVAcwARUlVZa+nb8TEdtwYsJT54FavlXVX+p8Rh3zJAua1lv2WWB5G2WbPgfqXMVZ7iPxxAI6MlBql9TNGlncRQMj76v1f2xQHCsoPAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = 'lv10bo2';
export var propertyIconContainer = 'lv10bo5';
export var propertyNameContainer = 'lv10bo3';
export var propertyNameContent = 'lv10bo6';
export var propertyNameInnerContainer = 'lv10bo4';
export var propertyRoot = 'lv10bo0';
export var propertyTableContent = 'lv10bo1';
export var propertyValueContainer = 'lv10bo7';
export var section = 'lv10bo9';
export var sectionCollapsedIcon = 'lv10boe';
export var sectionContent = 'lv10bof';
export var sectionHeader = 'lv10boa';
export var sectionHeaderIcon = 'lv10boc';
export var sectionHeaderName = 'lv10bod';
export var sectionHeaderTrigger = 'lv10bob';
export var tableButton = 'lv10bo8';