import "../../core/src/desktop/dialogs/setting/workspace-setting/billing/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/billing/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUwU7DMAy97yt8QWKHTG0ZA8KNP0kTtzVqk5CkrAPx76jpxtptdEKcqubZz8/PTlZv7ZuXJoHPBUAjXEmaBWM5ZGvbPS++FqshII0BirytxY5DUWP3vID4ZYocykBGc5CmbhvdI6KmUjMK2HgOEnVA1x+X4pw7i9xbUqHikCbJzQi7i5g0tXEc3oW7ZUwUBWlk7xkL2AXmURqthNstoyCjA/P0gSfR8bzzyxH1+q8tXdJ+/1eShjSrkMoqcHjIbDfj1WvrAxU7Jo0OqMMR+qm+uVz9LNFbIZHlGLaIUYUVSpEuOaR7CblxCh2H1HbgTU3qzOxa7NAx0p4UvsRoNiQtj/nMCUWt5/A4MekhyuzVcUghgWSEPQ7Y3NgqtjnOdrv3bpOMWZ6us+TC43j84npK3JeTuvd93fmNxMZWwtOQLFvn+0hr6GR4+dmdm7omLw937mKlmwmDut6ib36xdr5F66g5XLm6Bw4rnWUTBXi5hyh2PWzeL2XyNgSjR4WOnCsrfGCqxbm3YZ+Pzhk3zi7+MfYfknLyYu0bOVgw/H99A1p2xsxaBQAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var billingFrequency = 'quqsco9';
export var billingHistorySkeleton = 'quqsco5';
export var cancelPlanButton = 'quqscob';
export var cardLabel = 'quqscof';
export var cardLabelContainer = 'quqscoe';
export var cardLabelIcon = 'quqscog';
export var cardName = 'quqscod';
export var cardNameLabelRow = 'quqscoc';
export var currentPlan = 'quqsco7';
export var currentPlanName = 'quqscoa';
export var history = 'quqsco1';
export var historyContent = 'quqsco2';
export var noInvoice = 'quqsco3';
export var paymentMethod = 'quqsco0';
export var planCard = 'quqsco6';
export var planPrice = 'quqsco8';
export var subscriptionSettingSkeleton = 'quqsco4';