import "../../core/src/components/doc-properties/table.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51W227bMAx971cIBQakD8qc9LJUXYFhP7APGPbAWLSjzZYEiXaSDf33QZLtKZmzdXtKTJGHh0ckoeVxY9ebZs1+XDHGefoqBFs9FPbwlNlWgvXgFpxDVSmNvDKauG9vgs9eSdoJtiqKN+EzHlXQquY4GwTaD8cx2oKUStd8a4hMK9hqEzK/XC1T4ttITSpvGzgKVjUYee1Q1TsS7HbgOaAIdpe+W3C10hPoJlm/dp5UdeSl0YSaBPMWSuRbpD2iDh7QqFpzRdh6wUrUhC6YS9MYd1ZNv+aEB+IeS6MluFROrHE/sLsviqyUu/lSwq9gq1l+4YyjlpFD53wgYY0aaSVB1XcUbF2cyHYfc72W9hT2EMMmMQv2kISbyb01TqLjDqTq/KD7LxyxMz26iLaF8lvtTKclv8CngSM6nvnF4E89ugZO6L27rCCXymFJymgRyu7aeJ012OnyM7EutPKUZ/OveU7axhM4Os8+Kiixgq6hpxmZp/SPMf3Y4kWmNhkrWLG8twfmTaPkBSmV9krixxSTQucmNQ1JpMjyRoX/l3nC2P4rhjVeJZvDBkj1mKGVr0NzZp+vlKjWOtH6LIGAW2csOgoT1jRgPcrna3IdXn9h85m00TkPGU//3kl/Hj1CR2pcGFkXDLvrfLedrJS7opgUH4ZzvNVxZ89N5tzuS22a1eb7+ry+1bgA5stRpdHzm0QOG2DE/BOAdao9C8cYZCyUio7ipDvLAXnec5V5VvGIHOixteL/yriWFcu1ZwgeudLcdLkQVeqV31tkQgsIodsICBePhcQ6516frb1LC2+/U4Q3aRAPfLjFEppyceKPUpFx6fyGvWXr+VE+8F990++CLahUNWbPj4JBRyajuBM78Iuzmeih6fBLMmJr6TgWfjM/Ex9CK4HS6FivcG+NI7aIvNj7Z/ZwV9hDilzmr4y/vCkOPpb3EvBblAqYdUpTjnM74JwRCkGTz+MrfCCV6sMtPl8bi3q449+eD7frNAVZsLyc4OUnQASUyVYJAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionContainer = 'y8p28lc';
export var backLinksMenu = 'y8p28lg';
export var backlinksList = 'y8p28l7';
export var collapsedIcon = 'y8p28lf';
export var fontSize = 'var(--y8p28l1)';
export var pageInfoDimmed = 'y8p28l5';
export var propertyActionButton = 'y8p28ld';
export var propertyConfigButton = 'y8p28le';
export var propertyRootHideEmpty = 'y8p28lh';
export var root = 'y8p28l2';
export var tableBodyRoot = 'y8p28la';
export var tableBodySortable = 'y8p28lb';
export var tableHeader = 'y8p28l3';
export var tableHeaderBacklinksHint = 'y8p28l6';
export var tableHeaderCollapseButtonWrapper = 'y8p28l4';
export var tableHeaderDivider = 'y8p28l9';
export var tableHeaderTimestamp = 'y8p28l8';