import "../../component/src/components/member-components/invite-team-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/member-components/invite-team-modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V246bMBB936/wS6XmwRGkSbp1PqKfUBk8wGyNjeyBwFb77xUONEATNlrtI8NwzpwzF7a/4ujltS0pYn+eGMusIX4GzAsS7BhFpzHm8RUEa6T7yrnMMjTAQ7zgx02fVEqXo+GJJbKlYLuoak9Pb0/bET4O8IuseJm1C1kKfaVlJ1imoe3BX2pPmHU8tYbA0OUFB6NmH3+bUmjISLB91U6KcxdZF1bGUqutW2hqdpygJV45LKXrNjOC/YPVpWAIXP9KaswNR4LST8O5rAQ7zmojWwl2WNhxmBOi0cH3D/OeUVExNoexYujz+Lza6NZvHjeNscQ6BY47qbD2QyOmykRhG3BBXyLT37mztVH/42rZgePXDB4++9mA03LRncNWpoQNBMy1MqGsCulx0FM732cqyGStqQ9VFnvDODRgyAtmrIEFk5YJ6DUiTK2Z+THxNj4uzdgq9DLRoAKkrWSK1Am23d+pcE3dALV5TAmTgXIYjDiKvpw+Y8QnFMfbSxM2WKGDlNAa0UuqS/NvOeLdwqPv1/N0b0J9eXV6PGGH/oRNYJ4/VM3zopgft1HWtn25APLTpidZQ/IkqfYcnLNuiXRnxyfQaYCupFJo8uGkhl/CGBouaoj1i5lpe+adYA16TPR81tRt024a/P4Se0itUTf8uTMXb38B1Tk2wukGAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentStyle = '_10jzxmtc';
export var errorHint = '_10jzxmtb';
export var iconStyle = '_10jzxmta';
export var invitationLinkContainer = '_10jzxmt8';
export var invitationLinkContent = '_10jzxmtd';
export var invitationLinkDescription = '_10jzxmte';
export var inviteModalButtonContainer = '_10jzxmt2';
export var inviteModalContent = '_10jzxmt1';
export var inviteModalTitle = '_10jzxmt0';
export var inviteName = '_10jzxmt3';
export var modalContent = '_10jzxmt6';
export var modalSubTitle = '_10jzxmt7';
export var pageItem = '_10jzxmt5';
export var pagination = '_10jzxmt4';
export var radioItem = '_10jzxmt9';