import "../../core/src/components/notification/list.style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/notification/list.style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVTW/bMAy951cQBQo0wFQ4btJkGgYM/QM77DjswFiyzVWWDElOnA3974Plj8Rp0yTdyZb0yEc+kdS3Z7lLLRbSAS1dVscr+DsBiG7DB8CUmJDfcYi+TABeJgCz6PXhrD18mdy3TqIAKLBmuaQs9xziZVzWDWpLwuccHh6jZj1YzIKFIFcq3HFIlQzo5ssEWZl4MppDYlRV6OYkw5LDauQjvtYHKso0Iy8LxyGR2kvbbP+unKd0xxKjvdT+8Ciwdpn0qc1W83ajRCFIZxxmj2UN88UouIe3g7s+hE7AeTSOol8XaDPSbG28NwWHWRfb2lghLbMoqHIcFtFt2MXkObOm0oIlRhnLYYP2jjFMU9KSbWKmcCctO8A5mRgt0O6mjYMTVpQYzUpLRYsbRJgHEU4YeVn7AyOA1GjPHP2R+zRUgx1qqruIYBiE3Os0UC7OUo4zOksaXUD6+B6p8+grx6S1xl6V51Bf87Jui3AgXF5W+tZs3yiGvnyNow4mFXrayBHpqkXt+w4gqaxrMiwNHQmw5LnZSMu18Xc/BXpkghyulRRfb7yt5M2vTzxHd7euvDe6BU+nIYkP1GQw/76RVuGo3D4Hh+MRhpoKbNPsB94iigoHEp2E1NgtWuFg5g78YPCz1wfXzqjKB31sNwSivs/avluNpl181Kz9+nyurUChoZ7C7w+jSEz3t8ghul+UNbhm/4RUpB0J+dTeemvWeaiZy1GY7RFxx9qeBeihiMe3DCOZDh+FAbn+0HjvCvOy/hi4kv/o+Phkxw/uxfkZpjCRuVG9zGcJxvHLQBCMth1iEUXvTNtXg3PQmXRD1GxtpPWUoOpHljflIWXbo6Qxafp+aNIrUx38pcGwK/6UfP+OnRo+g2EWDN8Mdnjauo7rKuHCF+gfNTCT+egIAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var containerScrollViewport = 'i7sgx20';
export var error = 'i7sgx26';
export var itemActionButton = 'i7sgx2f';
export var itemContainer = 'i7sgx27';
export var itemDate = 'i7sgx2c';
export var itemDeleteButton = 'i7sgx2a';
export var itemList = 'i7sgx21';
export var itemMain = 'i7sgx2b';
export var itemNameLabel = 'i7sgx2e';
export var itemNameLabelIcon = 'i7sgx2g';
export var itemNotSupported = 'i7sgx2d';
export var itemSkeletonContainer = 'i7sgx29';
export var listEmpty = 'i7sgx22';
export var listEmptyDescription = 'i7sgx25';
export var listEmptyIconContainer = 'i7sgx23';
export var listEmptyTitle = 'i7sgx24';