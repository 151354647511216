import "../../core/src/blocksuite/attachment-viewer/viewer.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/attachment-viewer/viewer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VU0W7bMAx8z1cIBQY0QBXYWYMNCrCH/Yls0Q5XRRQkJk4y9N8HSW4aJ2mxvlgWSfPOvJMWcXX6UVeV+DsTwlNERnJKBLCacQ/rmRAnic7AQYkq7QxGb/VRic7CIQXSqkSdXmkPobM0KLFBY8ClWICIJ1DCkYP17HW2GAFVAx0FyLgtOQbHSjw8rCc0dBPJ7jjTYPIjhYD9hsf3hphpO24sdG/xM2lZT1Ad8eOiD2gMun5+SaLR7UsfaOeMbMlSUGKvw6OUuuvQgdwvpdVHCPKiLkJLzuhwnF9inNtfdievW+SjEtViuVpP4cqAlpU/5MdVFre6ByUsOtBB9kEbBMeP1+SaHTO5lG8aCNJAp3eW56L2hyfBQbvodQDHKTB/umnHVMb6dPPXX2p8MYf6E0vdNZE0GKAtxS3Z3Tb7Z0DDGyXqqvqWthso4pf9GWyZwW7a/tlFxu4ozwaLXrcgG+ABij3f+q2WZfBeF+lEneT4ea3Gf5jCB9wWSwjRkeNR3noEyKFhRH2uslk/8BvDgSceS3YPBoJk8nIcTLVYjSTfU5GPFpSIZNFcpT61NrqIBn6X6vJRRs1EhqB9OsZpvX/Yz2p8L5b/uGApfolJ7Y1yfQJ7m5m22DuJDNuoRAuOIVxoJcuxr6cKyvGWKOF3aosT0bZcA3FDw3wKf3VLPd8nd++eyzN6T4C16CPGbOENMshsPSV8yCdgoGDGkTYB9ItMgfXs9R9aeng5kAUAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../core/src/blocksuite/attachment-viewer/pdf/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/attachment-viewer/pdf/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81WbW/bIBD+3l+BNE1KpRE5Tpq19FP3Yf8Dw9lhJWABTpxN++8T4BeSOsmiVdWkRLbhjrt77rk75rDMeXXI0K87hPaCuw1Biyz7/Hz3+24e9xZhjwtbS3ogqJTQPt8hRKWoFBYOtpYgBsqB8cs/GutEecBMKwfKEWRrygAX4PYAykvUlHOhKoIytMjrNrGUB0tb2uLOE0Ylm3l3EEarrG7vvX5B2WtldKM4QTtqZhjTshQK8C7Hkh7A4P1GOIiyusVW/AzmCm04GFzoNu6Erz7kOl207iCBIKul4Mky01KbczaFsoLDt96Gf4webCjXe4KyukWrukW5f/H/eJQzVNmaGlAOF5KyV5xn2RdkqoLOsi+o+80X2X04Ue/AlNKftxGcR0w9ZhsQ1caR8N6h//wvieu3hvQsp4ngn5gLA8wJrQhiWjbbmGltRVyjhdWycXA5J30yPD5HTPE8QZlfMjHGZdYnzDm9Hb9THFLyrAfyRIOGctFYgh7rjyXDQN3L+olcbcSWmkNQL7VyHjogXeV0S/su4ocsYCT9WT0KPZZn7DloHbbAtOLRypDtVcj2mEIDkjqxg0lOnedAmpHYWKYYPFqdMy0lrS3wY7YprSCVI0q72Sh837UOUwmFe1Y8HnWXh2n6TpXTCUtWH8mS0d+5BQnMdUgcn/gpf3pZfn9JxNdvW/j/Q/cTzbf8jgaCVmO9GyH0Pu2evqEr4Z2wohBSuANB4V1OE3Ir1EC7Uri0IZ6y8Tx3r82yAfuvt9TKrf33pA+e5ezVsZignlZ8RICgRRLQ40lAaf8+M5/zVddip5zrMaeN03EyCB8chh0oZ99U99MF831xL9YRi24iLLoivaE1VbT2LDhqEjStomVnove+/36n+km3LtdLf6EZ3CxuupOdQmD0/u+uahGhfGIa+4UwO/bGyyjtn4mD7AYHR6W5M41i1EHQvjAmeNruJZRu6G3pjFxNzMj11IzMr8/IdA5PMTwIjRsgpaitsOFa47OHA7gTSMVYr4321XuM9j9ZTzVL7QsAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var pdfContainer = 'e32dgy6';
export var pdfControlButton = 'e32dgya';
export var pdfControls = 'e32dgy9';
export var pdfFooter = 'e32dgyb';
export var pdfFooterItem = 'e32dgyc';
export var pdfIndicator = 'e32dgy1';
export var pdfPage = 'e32dgy2';
export var pdfPageCount = 'e32dgye';
export var pdfPlaceholder = 'e32dgy8';
export var pdfThumbnail = 'e32dgy5';
export var pdfThumbnails = 'e32dgy3';
export var pdfThumbnailsList = 'e32dgy4';
export var pdfTitle = 'e32dgyd';
export var pdfViewer = 'e32dgy7';
export var viewer = 's5z7100';
export var virtuoso = 'e32dgy0';