import "../../core/src/blocksuite/database-block/properties/file/style.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/database-block/properties/file/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WTW/bMAy951cIGAasBxW2m36ptwLbdYf9AkWiYzaKJEhK4mzofx8k24m8OlkCFDkkMflI6j2S8q215r4syJ8ZIcIo4xjZcveNUl7XqIFuK4rCaGodrrnb37zM3me3CVMmjOVSol4y8mRbUsTPy4yQHcrQMHI3L2x7RFRjRHG03CVLjyqL4msMUhsdqMffwEhZ5XHmyVuit4rvGakVtNH/beMD1nsqjA6gAyMCdAAXTVzhUlMMsPb54zzD3LaHRzvAZRP+YSJZWn8TvZbcphPH32YLrlZmx0iDUoI+1nl/jtUAbaAehNFyzOtDjvpSfn9++JE4TQAJwjge0GhGtNHwoeT7ohOgwQDUWy4gOu4ct8cMj9P8xW8q0YHo4gujNuvsOE8X0p6y0gWEHUQ2MsXnth1Yu4zB5+mUJ+RMMef/i8lTzI/2nuGjAZRC69Gf5HOigYYki+nCm16lquiq7Dt++JsU8I1DvWLdIF3f0wvjJDjquMSNZ6TqIouN87GfrMHB8bppX7AmMpOOteBitXRmoyU9EUTxPTia+SXwzy04xfOoYmrurfHYdaADxQNu4SDuQ3ea6daNojAySHO6AeRVTTXVKAcZR5pDijvYyru+2ClBBkjd8Tkp2SFQomVALKcYG7teIpDkgS+4B8pD4KJZgw60RgW/jEL5eoDfjMa3tC3J1+R4c6oY+sBMdfrwnzN8AyHNiPVqfi5v9/S4OafquLQvTk7mUBhe1WZ9wcHYjmcfhcgMZwcNtUcJr51nB8iG7C0VEvMzUl5zxHxH5/f85EIZsq3Gl3xZnRvZidumT1uO3hzUp+3s97+ub/+R8QgAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cellContainer = 'ppo51c';
export var fileInfoContainer = 'ppo514';
export var fileItem = 'ppo518';
export var fileItemCell = 'ppo51d';
export var fileItemContent = 'ppo519';
export var fileItemImagePreview = 'ppo51e';
export var fileListContainer = 'ppo517';
export var fileName = 'ppo51a';
export var fileNameStyle = 'ppo51l';
export var filePopoverContainer = 'ppo511';
export var filePopoverContent = 'ppo512';
export var filePreviewContainer = 'ppo51g';
export var fileSizeInfo = 'ppo515';
export var iconPrimary = 'ppo510';
export var imagePreviewContainer = 'ppo51h';
export var imagePreviewIcon = 'ppo51f';
export var menuButton = 'ppo51b';
export var upgradeLink = 'ppo516';
export var uploadButton = 'ppo513';
export var uploadButtonStyle = 'ppo51j';
export var uploadContainer = 'ppo51i';
export var uploadPopoverContainer = 'ppo51k';