import "../../core/src/components/root-app-sidebar/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/root-app-sidebar/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51U227bMAx9z1dwBQY0DwqcW9cpwH5kKAZZom2utiTIdC4b9u+DZbe2E6/r9uI45NEhD3nk1bdtotOtW8PPBYCh2pfqIiEr8XxYAKiSciuIsaolaLSMoQ1/b2qm7CK0s4yWJdReaRQp8gnRtohceQmP/nxY/Fqs+hqbf6oxMAB4ZQzZPP6HZMy5jZwnMlzINgORVsJ6DNq9B7Sf7679FYYCaiZnJWhXNtUgcTeVuIcvYOgoreP7r0axEoaOZDA8LSN9pUJOVqSO2VUSEvhAlXeBleXDkGbnr3NDiYf/7xMgdcFgEEEZauqb7j9F6tdhrzfdId2E2gUJ3lG3nNGJTqQP7un2bPd4eRkfe5yCdzOQzxGSOcvihJQXLGGXxN2VZFEUfWyTdD1GYE0/UMJRhXshVJa1uBg/18uow5WtjEme8cyiRu2sUaE1dOlCBN8M+O+enzXyoEhFRX9swgeqpi30bgid0mS1x2rMl0a+l0Gsk9kN77uoO2LISneSUJAxXbve1dR5JWCpmI4Yi5IV/U3ZPCTTnaRSppi5gL2SfhB3d1M6ldaubDjSka2R+0uXKv2cB9dYI+amkJZKP0fhzitNfGk1J7txA/pKcvJxRjLZAgPxWxV7umQ5JjcT8hdjvZo08ee3btGVV/edV99jy1k/zw3INwHFqSDG5VTcNe7KSYPEbnGjb/NvFYe8wv8FAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var aiUsageBlock = '_30cb3o7';
export var bottomContainer = '_30cb3oe';
export var cloudUsageBar = '_30cb3ob';
export var cloudUsageBarInner = '_30cb3oc';
export var cloudUsageBlock = '_30cb3o8';
export var freeTag = '_30cb3od';
export var operationMenu = '_30cb3o5';
export var progressColorVar = 'var(--_30cb3o0)';
export var quickSearch = '_30cb3o3';
export var quickSearchAndNewPage = '_30cb3o2';
export var usageBlock = '_30cb3o6';
export var usageLabel = '_30cb3o9';
export var usageLabelTitle = '_30cb3oa';
export var workspaceAndUserWrapper = '_30cb3o1';
export var workspaceWrapper = '_30cb3o4';