import "../../../../blocksuite/affine/data-view/src/core/component/tags/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/data-view/src/core/component/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WTY/jNgy9z6/gLV0gGsTZmelWvXS3aNE9dLvAtOeCtuiYE1kyJDmJt5j/XkgeJ3Y+BhO0hzi2TFKPj4+Ub//Oqqy9b58W8M8NQGM9B7ZGAube6jbQjzcA3wQbRTsJy/hUWG2dhA2674TAsmRDYrMUgXZBNI5rdN27aJdbp8hJWNzeNzvwVrM6cdLYkRNsPCv6lOxFrrFY9/ejMMKh4tZL+NDs0ioW65WzrVHiAp4+9MjObshp7L6iIf0SeSd8hcpuj7z7RZElq9KaIEqsWXdHZqM3ybLGndiyCpWEu8Wix9mgUmxWe9yKfaOxk1BqSgvxXyh2VPS8F1a3tYlvVthIuItuzze3Q5myVKaTKKh5ZQQHqr2Egkwgtw++dTFQvO6jPhyBO95lmXaJ3hIyyGAxrqexhk6ImX2Oe87mMHu0rSsIHtF4eD+bw1fbNGz8HLBpNAnf+UD1HD5pNuvfsXhMz79aE+Yw+430hgIXCF+opdkc/sTK1jiHj45Rz8Gj8cKT43IOs48xHvwcqw+/1PaJ0+60sgR/fR5WDguPXZ1bHW2+2GAnflep+kR4waHxDToyIRro6FkRr6ogYbnsmU5cef5GErK7fsm2IZoOfI7Zl7LRWFBltSKXSnEO3cimh/JuEuV939CTEr+epqfCGjUkegZyWtq+pHa/WFxMt/XkhCdNRTiT311CFrtl8Nu3S+zRUtut6CRgG+yFYP+9j+7P99FT6wOXnSisCWSCBN9gQSKnsCUyr3TamOf9b9Q2+/k11KF1PhaisdyHGGF7SNgOQnvDbKsicX/0A24qg++vGhj7NBbDwDqwdymVbcWBRCIq1mcYNElSQz0lkNbcePbjKkuoWKme12G6ZBfPCoUBc/Qk8sPh8LrQPvRj7FTIZ0V7DtXFJEa7/HAVwxcasKi4ERpz0qkXpzXEq3Y4zWMUKn+j7g/hLh0tB1aXi9fkcaJ0gA17zllz6MZcX2CGC2smE7hGt2IjNJXhtK9zmVrh/+ygBzhibww/3eup7IpkNXwJ9CwMYsuWZ7nK3vpVk7chWCNWDvOcnFBUYqvDmBf3MlKn7Ist5WsOyXE/Ln3l2KxlnFMj+OqqrnnruTlF9zJczpXyp5oUIzSOTUhIbqefqCP1DofB883zv0GU5mXICgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var selectOptionContentStyle = '_1h1u5uja';
export var selectOptionDragHandlerStyle = '_1h1u5ujc';
export var selectOptionIconStyle = '_1h1u5ujb';
export var selectOptionNewIconStyle = '_1h1u5ujd';
export var selectOptionStyle = '_1h1u5uj5';
export var selectOptionsContainerStyle = '_1h1u5uj4';
export var selectOptionsTipsStyle = '_1h1u5uj3';
export var selectedStyle = '_1h1u5uj6';
export var tagContainerStyle = '_1h1u5uj7';
export var tagDeleteIconStyle = '_1h1u5uj9';
export var tagSelectContainerStyle = '_1h1u5uj0';
export var tagSelectInputContainerStyle = '_1h1u5uj1';
export var tagSelectInputStyle = '_1h1u5uj2';
export var tagTextStyle = '_1h1u5uj8';