import "../../../../blocksuite/affine/blocks/block-table/src/table-cell.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/blocks/block-table/src/table-cell.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+1VwY7TMBC99yt8QdoKuWq6dNn1nuBPJva0NXg9kT1p00X8O7IT06QsXVBB4sAp8Yzz5s2bZ2fhuwfTLMWXmRANRcuWvBIBHbDd4+NMCHB266VlfIpKaPSMIYVrCgaDElXTiUjOmlNQanIUlNhDuJESNhvrUe5XkqF2KPs98+l2B01EJcpbStpIiUSiAy1TCjF2LDMfJSJD4BTcY2CrwZUEU/M4+zpb5Maqs8agjuRazgV2aLc7VmKZoakZ3hxuSvBgDe+UqJbLN2lpbGwcHJXYOOwuSPOpjWw3R6nJM3o+pQqr28xKyrxY/SiU1eRlRE3eQDhmqXQbYtK0IVvKPEvrDXZKrEZcV/dNN26uuuvXNejP20CtN5ens0MwGD6edhvcQOt4PK8AxrZRiaFUTZ2MOzB0OAOtW+bUSM5lAGpAWz4WzQP4MpghI5aLVRQIEaX1klr+s7qrHe0xZPW/U6lG+QXoZPtXxtMrZb2xGpiCHGbyIX877bP6He1PiJkGMJr5idy7C1a+5N7lxA4/s3J6SmMD6h5dk2uf/DVir/+eyYupS1fF9P9NnnR/xeTrf9nkd5nV1VoIsYVGiVWaXYF+n6EHB91ODXR72T+9Ri+5Y52OU6lwf+GEXifP+Tn4VVMNQ5O4R89RCU8eT3wfMt98d8hqKogGp2/SZSHeJhXno8O3HosKGaKcWzzIgNE+545DjyQHbc+p9P/0glNnnP7ykpOx6XFmYDkweYlkaWDC0mSMmpjpqTAqORyJ0Bf+BjVVPxATCQAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cellContainerStyle = 'nx9dp0';
export var columnIndicatorStyle = 'nx9dp9 nx9dp8';
export var columnLeftIndicatorStyle = 'nx9dpb nx9dp9 nx9dp8';
export var columnOptionsCellStyle = 'nx9dp1';
export var columnOptionsStyle = 'nx9dp3';
export var columnRightIndicatorStyle = 'nx9dpa nx9dp9 nx9dp8';
export var indicatorStyle = 'nx9dp8';
export var rowBottomIndicatorStyle = 'nx9dpd nx9dpc nx9dp8';
export var rowIndicatorStyle = 'nx9dpc nx9dp8';
export var rowOptionsCellStyle = 'nx9dp4';
export var rowOptionsStyle = 'nx9dp5';
export var rowTopIndicatorStyle = 'nx9dpe nx9dpc nx9dp8';
export var threePointerIconDotStyle = 'nx9dp7';
export var threePointerIconStyle = 'nx9dp6';