import "../../core/src/desktop/dialogs/setting/general-setting/billing/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/general-setting/billing/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WwXLbIBC9+yu4dKY54JFcx3HJTA/9kQ4SK4kUAYVVbLeTf+8Iy42wsJzEPWkE7Nv3dt8iLX/khVW/TJmTPwtChPRW8QMjlYL944KEJxXSQYnSaEZKo7pW9ztcyVpTidB6RkrQCK5frrllZLW2+8fFy2J5Ql8F9J0U2DCSZ9mnaPdLOvdT51FWB1oajaCREW95CbQA3AEEDpYLIXXNSL6yIaQwToBjJLd74o2Sgjxz95lSXlVSA31eUcUP4KjUXgr4Hk7TY9Ddazx1XMjOM7I9k7EORHt+jOQkI1m0ez8WyTs0PWLLXS01RWOncJsjnNFIvfwNLCYb1hu6CcTCyw5k3SAjmyxO/HAdp+Ae7qKgbQga0/vXNcF1Dc50nnpAlLomS81bCAGlUcaxSVk9cuw8BeeMi/N8nQtD2CP1UBotuDu8Kr0kY+9jcH5deQg5K+B9X8A0qcAIWttwLz0NJ0J82TnfH7ZGHq0+YlG8d3bSQ1K+F6aVmjaDpIdhAi6M5WSWTluj/OKjYziYqDCIpp3aHK43ybcXXE6I6g+dVK4GlXN2sk62RzONOFRvNkqcLzvmi4itL7snZemIxTfCL85D4bgWI8tNLTuCqm+6r0GLCK05L0++TQhPdmTzwY7I/9iRmXm+0pGndBnnPm755szfP2/y99sKl5yDEQeV1hEIr2c7VHSIRqd7pJaWe6Sim737B4TE3d/ecD2PYHT09zDIOZUiP79GzeTDNnh5WFJQ4emP4eUv0EfljgAJAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var believerHeader = '_1bplqocd';
export var believerPrice = '_1bplqoch';
export var believerPriceCaption = '_1bplqoci';
export var believerPriceWrapper = '_1bplqocg';
export var believerSubtitle = '_1bplqocf';
export var believerTitle = '_1bplqoce';
export var billingFrequency = '_1bplqoc7';
export var billingHistorySkeleton = '_1bplqocc';
export var cardLabel = '_1bplqocm';
export var cardLabelContainer = '_1bplqocl';
export var cardLabelIcon = '_1bplqocn';
export var cardName = '_1bplqock';
export var cardNameLabelRow = '_1bplqocj';
export var currentPlan = '_1bplqoc4';
export var currentPlanName = '_1bplqoca';
export var history = '_1bplqoc1';
export var historyContent = '_1bplqoc2';
export var manageMentInCard = '_1bplqoco';
export var noInvoice = '_1bplqoc9';
export var paymentMethod = '_1bplqoc8';
export var planAction = '_1bplqoc5';
export var planCard = '_1bplqoc3';
export var planPrice = '_1bplqoc6';
export var subscription = '_1bplqoc0';
export var subscriptionSettingSkeleton = '_1bplqocb';