import "../../core/src/desktop/dialogs/setting/workspace-setting/integration/readwise/import-dialog.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/integration/readwise/import-dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW23KbMBB991eoD51Jp5EHiE0J+ZiOAgsoEZIqycZpJ//ekcC2ZLAJvTwxs9LunnP2ItbfYwVlFpEI/Voh1NHSNDnaZJE8PK0QaskBD7aCsOIujqJ9hzB6SOThi73QAK0bk6N0u2+ODkfbNsqGKJSfjC3ld+7g/hyxOUZ0IUuqJSNvOaoYOHf7xSVVUBgqeI4KwXYttydGEa5pbz1nRtE6zjQCouHey+2Zn1bvq/WJ+VoDg8I4Af45nyBRp6ihvJ7NFD9upjO5g/lMsctQCW6wpj8hR/G2j+dM3YlPZG2McjglSYbEhWBC5WhP1B3GpKrsnX2CDRwMloq2RL196VGrmnL8LIwRbY4S6+0BSUZAkgkgmykg0TwQDYXg5RUocXqB5cFhuRLveWeM4AG1sVZesE1Qw+jUprUSXY7i4O527i5Cjg5htLbtDdyAslZJypLyGhsh7UwuFcSXfShrKPFltVJ/Bxx77DP66nJ7GucokweE+17x/L85/9H8Ol6YGmi1z+5lpw2t3nAhuAFucgS8tPaayHP5Awl6o5cwW5TQBf7/Kj4G5bYKLtpqXiSyoMmep6UY9MtRKg99hElxvEAwGtkPsS7QjQhTQz+5fRYPfddQA1hLUkCOuOgUkQGs6tbQ0+Ji5IfWT06vn9soqgeXeSR6XvYe+kRbKZQh3IzoXB57uGp/1C4r+5BcpTasCrEHVTF7FxijUlNtj87WhpYl8DkpGeWv4cPR3JLrig+9Vu7J0g6U000gMYPKnB1D3ePLVnv5wCLXu6IArUOkr7PsDChD+27w/Nis37mJPLf2Vo2Txxs1nhDzoyPh5ed/uB29EMKFCJs6uCBHxc8mZj2dmvV06BIwBpTTwC2qaL3sH8QD82P+oZ37J5r8FVn4TxS8WaPuVQ7kxLv2ty/n+2+BPziPzAsAAA==\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actions = '_1red80a7';
export var content = '_1red80a4';
export var desc = '_1red80a2';
export var empty = '_1red80a5';
export var footerDivider = '_1red80a6';
export var importDialog = '_1red80a0';
export var importingDesc = '_1red80aq';
export var importingFooter = '_1red80ar';
export var importingHeader = '_1red80an';
export var importingLoading = '_1red80ao';
export var importingTitle = '_1red80ap';
export var loading = '_1red80a8';
export var resetLastImportedAt = '_1red80a3';
export var table = '_1red80a9';
export var tableBodyRow = '_1red80ad _1red80ab';
export var tableCell = '_1red80ae';
export var tableCellLink = '_1red80ah';
export var tableCellSelect = '_1red80af _1red80ae';
export var tableCellTime = '_1red80am _1red80ae';
export var tableCellTitle = '_1red80ag _1red80ae';
export var tableCellTodo = '_1red80ai _1red80ae';
export var tableContent = '_1red80aa';
export var tableHeadRow = '_1red80ac _1red80ab';
export var tableRow = '_1red80ab';
export var title = '_1red80a1';
export var todoNew = '_1red80aj';
export var todoSkip = '_1red80ak';
export var todoUpdate = '_1red80al';