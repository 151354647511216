import "../../core/src/blocksuite/database-block/properties/member/multi-member-select/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/database-block/properties/member/multi-member-select/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VUS3LbMAzd+xRcNgtmLMVJU3aXC/QMNAlZqGlQJSl/2sndO9SHplzbYyfTjSQS0APwALzHuv41V+2c/ZkxptE3Rh4Eqwzsv89Y9+YaHaiAlgRT1rQbipaVbAR7bTqvxnrs7Q6MDLiF7lZqjbRKXjvUoRasLBbx/D577CMXVyLvXIwSnynkYgg5gg/npXUaHHdSY+vTrTS4Io4BNl4wBRTAHZ0FK5o989agZsFJ8o10QCFDU9ZYJ9hWui+cy6pCAr4tuZEHcBzJo4a33nNppFr33w95baKyqvV8h6FG6gq9G7pxuJHukMAjxJ77Wmq7OwGQKnI/2PI8yi50pFSwIkJskPjQj2I+zykUjCx1/bNtMEhwvFhKtV4525Ie0z+hrbIUuMffIFgxdCAi8BpwVQfByvKkefN4ukBFgH0Yi89rebp3UjdynzJ4Gou1W3CViQzKNtgMftF36Z9Sz3cq86sj5I8tOCMn+T6fz/fCaP5sfcDqwJWlABRy09ivl76CsaTxfCFPVJYyGqdNGn9tnY//NhanK5L2qZys7LPoiv0oUwFcwJOuvpxnKV/5C4wdO1mj1kCTASvPqkM5Hfj/JQO3kLNsQ7DEPShL+oSTr3dNTi7IVzm5opgp9GsX+hxMt5ZHAxiDjUffDWiNAbhvpOpEY5TtW1UhyVPK4luSLe5rh7QeFOPTy5QiyLsozinsMzmSniCXH4a8XQxvJfX9LzPIUHXeBwAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatar = 'hhq0cu9';
export var loadingContainer = 'hhq0cua';
export var memberDeleteIcon = 'hhq0cu5';
export var memberInputContainer = 'hhq0cu1';
export var memberItem = 'hhq0cu7';
export var memberListContainer = 'hhq0cu3';
export var memberName = 'hhq0cu8';
export var memberPreviewContainer = 'hhq0cu6';
export var memberSearchInput = 'hhq0cu2';
export var memberSelectedItem = 'hhq0cu4';
export var multiMemberSelectContainer = 'hhq0cu0';
export var noResultContainer = 'hhq0cub';